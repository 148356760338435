import ibm from './assets/img/icons/common/ibmlogo.png';
import gs from './assets/img/icons/common/gs.png';
import meta from './assets/img/icons/common/meta.png';

export const greetings = {
  name: 'Glen Pegado ',
  title: "Hi, I'm Glen",
  description:
    'A passionate Full Stack/Frontend Software Engineer based in Menlo Park. I have experience building Web applications using React, Node.js and Javascript/TypeScript.',
  resumeLink:
    'https://docs.google.com/document/d/1Wmg1698BPvbea5rNn1djmAx3_I45x4rxN4Rm5aPYrPg/edit?usp=sharing',
};

export const githubUserName = 'glenpegado';

export const contact = {};

export const socialLinks = {
  linkedin: 'https://www.linkedin.com/in/glenpegado/',
  github: 'https://github.com/glenpegado',
  twitter: 'https://twitter.com/glenpegado',
};

export const skillsSection = {
  title: 'What I do',
  subTitle: 'FULL STACK/FRONTEND DEVELOPER',
  skills: [
    '⚡ Develop highly interactive Front end / User Interfaces for high scalable systems',
    '⚡ Progressive Web Applications ( PWA )',
  ],

  softwareSkills: [
    {
      skillName: 'html-5',
      fontAwesomeClassname: 'vscode-icons:file-type-html',
    },
    {
      skillName: 'css3',
      fontAwesomeClassname: 'vscode-icons:file-type-css',
    },
    {
      skillName: 'sass',
      fontAwesomeClassname: 'logos:sass',
    },
    {
      skillName: 'JavaScript',
      fontAwesomeClassname: 'logos:javascript',
    },
    {
      skillName: 'TypeScript',
      fontAwesomeClassname: 'logos:typescript-icon',
    },
    {
      skillName: 'reactjs',
      fontAwesomeClassname: 'vscode-icons:file-type-reactjs',
    },
    {
      skillName: 'nodejs',
      fontAwesomeClassname: 'logos:nodejs-icon',
    },
    {
      skillName: 'npm',
      fontAwesomeClassname: 'vscode-icons:file-type-npm',
    },
    {
      skillName: 'sql-database',
      fontAwesomeClassname: 'vscode-icons:file-type-sql',
    },
    {
      skillName: 'mongoDB',
      fontAwesomeClassname: 'vscode-icons:file-type-mongo',
    },
    {
      skillName: 'aws',
      fontAwesomeClassname: 'logos:aws',
    },
    {
      skillName: 'firebase',
      fontAwesomeClassname: 'logos:firebase',
    },
    {
      skillName: 'python',
      fontAwesomeClassname: 'logos:python',
    },
    {
      skillName: 'git',
      fontAwesomeClassname: 'logos:git-icon',
    },
    {
      skillName: 'docker',
      fontAwesomeClassname: 'logos:docker-icon',
    },
  ],
};

// export const SkillBars = [
//   {
//     Stack: 'Frontend/Design', //Insert stack or technology you have experience in
//     progressPercentage: '85', //Insert relative proficiency in percentage
//   },
//   {
//     Stack: 'Backend',
//     progressPercentage: '65',
//   },
//   {
//     Stack: 'Programming',
//     progressPercentage: '85',
//   },
// ];

export const educationInfo = [
  {
    schoolName: 'University of Miami',
    subHeader: 'Bachelors of Arts in Economics',
    duration: 'August 2014 - August 2016',
    descBullets: [
      'Minor: Sociology',
      "Activities and Societies: Men's Soccer Team, Sigma Alpha Pi, Economics Club ",
    ],
  },
  {
    schoolName: 'Galvanize',
    subHeader: 'Software Engineering Training',
    duration: 'January 2018 - July 2018',
    descBullets: [
      'Galvanize is an immersive 6 months training program that specializes in Software Engineering with over 1000+ hours of coding ',
    ],
  },
];

export const experience = [
  {
    role: 'Software Engineer',
    company: 'Meta',
    companylogo: meta,
    date: 'April 2024 – Present',
    desc: 'Worked on WhatsApp key features for both mobile and web that serve over 3 billion users worldwide - focusing on privacy',
  },
  {
    role: 'Software Engineer',
    company: 'Goldman Sachs',
    companylogo: gs,
    date: 'June 2022 – May 2023',
    desc: 'Developed UI features on a internal trading platform for both clients and traders',
  },
  {
    role: 'Software Engineer',
    company: 'IBM',
    companylogo: ibm,
    date: 'February 2020 – March 2021',
    desc: 'Worked on Learning & Supply Chain Blockchain Enterprise, contributing to the 2020 IDEA award winner in the industrial sector',
  },
];

export const projects = [
  {
    name: 'Rooted SF',
    desc: 'Developed an e-commerce application that allows signed-in users to sign-in and buy products using Stripe',
    link: {
      name: 'URL',
      url: 'https://github.com/glenpegado/rooted-sf',
    },
  },
  {
    name: 'ChatIgloo',
    desc: 'Web Messaging app the allows multiple users to connect to the same server',
    link: {
      name: 'URL',
      url: 'https://github.com/glenpegado/chatigloo-frontend/tree/master/chatigloo-frontend',
    },
  },
  {
    name: 'The Vibe',
    desc: 'Built a social networking website for media sharing using one-to-many and many-to-many schema with PostgreSQL',
    link: {
      name: 'URL',
      url: 'https://github.com/glenpegado/The-Vibe-Frontend',
    },
  },
];
